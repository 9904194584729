import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, useRoutes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import LoginPage from "./Pages/Login";
import Verification from "./Pages/Verify";
import RegisterPage from "./Pages/Register";
import Forget from "./Pages/Forget";
import Reset from "./Pages/Reset";
import Card from "./Pages/Card"
import Dashboard from './Pages/Dashboard'
import axios from 'axios'
import { StringParam, useQueryParams } from 'use-query-params'

const HOST_URL = process.env.REACT_APP_HOST_URL
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL


// Protected Route HOC
const ProtectedRoute = ({ children, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Check if user is authenticated, in this case, by checking if token exists
    let email = localStorage.getItem('email');
    let phone = localStorage.getItem('phone');

    async function fetcher() {
      if (!email) {
        return await fetch(`${BACKEND_URL}/memberCookies`, {
          method: 'post',
          body: JSON.stringify({ phone: phone }),
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => res.json()).then(res => {
          setUser(res.User);
          setIsAuthenticated(true);
          setIsLoading(false);
        });
      } else if (!phone) {
        return await fetch(`${BACKEND_URL}/memberCookies`, {
          method: 'post',
          body: JSON.stringify({ email: email }),
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => res.json()).then(res => {
          setUser(res.User);
          setIsAuthenticated(true);
          setIsLoading(false);
        });
      }
    }
    if (isLoading) {
      fetcher().then(token => {
        if (!token.success || !user || user.verified === false) {
          setIsAuthenticated(false);
        }
      });
    }
  }, [isLoading]);

  return isLoading ? null : !isAuthenticated ? children : <Navigate to="/dashboard" />;
};

const Routes = () => {
  return (
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <RoutesContainer />
      </QueryParamProvider>
    </BrowserRouter>
  );
};

const RoutesContainer = () => {
  const routes = useRoutes([
    { path: '/', element: <Card /> },
    { path: '/login', element: <LoginPage></LoginPage> },
    { path: '/register', element: <RegisterPage /> },
    { path: '/otp', element: <Verification /> },
    { path: '/forgot-password', element: <Forget /> },
    { path: '/reset/:userId/:token', element: <Reset /> },
    { path: '/dashboard', element: <ProtectedRoute><Dashboard /></ProtectedRoute> }, // Protected route with Dashboard as child

  ]);

  return routes;
};

const App = () => {
  return <Routes />;
};

export default App;
