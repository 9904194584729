import { useState } from 'react';
import Card from '../Membership-card - update 10 April 2024 -01.png'
import Logo from '../Screenshot 2024-05-08 at 3.55.06 PM.png'
import { FaHandPointRight } from "react-icons/fa";
import axios from 'axios'
import { FaHandPointLeft } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import FadeLoader from "react-spinners/HashLoader";
import Modal from 'react-modal';


import 'react-toastify/dist/ReactToastify.css';

const HOST_URL = process.env.REACT_APP_HOST_URL
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');

function App() {

  const [cardNo, setCardNo] = useState('')
  const [cvv, setCvv] = useState('')
  const [showCvv, setShowCvv] = useState(false)
  const [loader, setLoader] = useState(false)

  const cardPattern = /^\d{9}$/
  const cvvPattern = /^\d{4}$/

  const handleAuth = async () => {
    if (cardPattern.test(cardNo) && cvvPattern.test(cvv)) {
      setLoader(true)
      // check card validity and then
      await fetch(`${BACKEND_URL}/cardCheck`, { method: 'POST', credentials: 'include', body: JSON.stringify({ cardNum: cardNo, cvv: cvv }), headers: {"Content-Type": "application/json"} }).then(resp => resp.json())
        .then(res => {
          setLoader(false)
          if (res.success) {
           
            if (res.type === 'login') {
              window.location.assign(`${HOST_URL}/login?card=${cardNo}&cvv=${cvv}`)
            }
            if(res.type === 'register') {
              window.location.assign(`${HOST_URL}/register?card=${cardNo}&cvv=${cvv}`);
            } 

          }
          else {
            toast.error("Wrong Card Number or CVV");
          }
        })

    }
    else {
      toast.error("Please input correct Card No. and CVV")
    }
  }

  return (
    <div className="px-8 py-8 md:p-24 flex justify-center items-center md:h-screen">
      <div className="grid grid-cols-1 sm:grid-cols-2 content-center gap-4 md:gap-8">
        <div className='flex items-center md:justify-center'>
          <div>
            <img src={Logo} alt="" className='h-12' />
            <br />
            <ToastContainer limit={3} />
            <p className='text-2xl md:text-4xl font-bold mb-4'>ACS Membership Dashboard</p>
            <p className='text-sm mb-8 text-gray-500'>Enjoy premium features and exiciting offers from ACS through this membership portal!</p>

            <Modal
        isOpen={loader}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="mx-auto my-auto">
          <FadeLoader
            color={'#4292cf'}
            loading={loader}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </Modal>


            {
              showCvv ?
                <div>
                  <label className="block mb-4 text-sm font-medium">Enter you CVV No. to authenticate </label>
                  <input id="text" className="shadow-sm mb-4 bg-gray-200 border text-md rounded-lg block p-2.5 text-gray-800 shadow-sm-light focus:outline-none focus:ring focus:ring-gray-300 w-full sm:w-80" text="name@flowbite.com" value={cvv} onChange={(e) => setCvv(e.target.value)} />
                  <div className='block'>
                    <button className="py-3 px-5 text-center inline-flex items-center justify-center mb-2 text-sm font-medium rounded-lg bg-gray-300 w-full sm:w-80 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300  bg-primary-600 hover:bg-primary-700 focus:ring-primary-800" onClick={handleAuth}>Next step &nbsp; <FaHandPointRight /> </button>
                  </div>

                  <button className="py-3 px-5 text-center inline-flex items-center justify-center mb-2 text-sm font-medium rounded-lg bg-gray-300 w-full sm:w-80 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300   hover:bg-primary-700 focus:ring-primary-800" onClick={() => setShowCvv(false)}>Fix Card no. &nbsp; <FaHandPointLeft /> </button>
                </div>
                :
                <div>
                  <label className="block mb-4 text-sm font-medium">Enter you Card No. to sign in </label>
                  <input id="text" className="shadow-sm mb-4 bg-gray-200 border text-md rounded-lg block p-2.5 text-gray-800 shadow-sm-light focus:outline-none focus:ring focus:ring-gray-300 w-full sm:w-80" text="name@flowbite.com" required value={cardNo} onChange={(e) => setCardNo(e.target.value)} />
                  <button className="py-3 px-5 text-center inline-flex items-center justify-center mb-2 text-sm font-medium rounded-lg bg-gray-300 w-full sm:w-80 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-gray-300   hover:bg-primary-700 focus:ring-primary-800" onClick={() => setShowCvv(true)}>Next step &nbsp; <FaHandPointRight /> </button>
                </div>
            }
          </div>

          <div>
          </div>
        </div>
        <div className='flex justify-center items-center bg-gray-300 rounded-xl'>
          <div className='p-12 flex items-center justify-center'>
            <img src={Card} alt="" className='' />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
