/* eslint-disable */
import React, { useState, useEffect } from "react";
import phone from './message (2).png'
import toast, { Toaster } from 'react-hot-toast';
import { useQueryParams, StringParam } from "use-query-params";
import HashLoader from "react-spinners/HashLoader";
import Modal from 'react-modal';
import { customStyles } from "../modalstyle";

const HOST_URL = process.env.REACT_APP_HOST_URL
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const Verification = () => {

  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [activeInput, setActiveInput] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const storedCountdown = localStorage.getItem('countdown');
  const initialCountdown = storedCountdown ? parseInt(storedCountdown, 10) : 300;
  const [countdown, setCountdown] = useState(initialCountdown);
  const [timerActive, setTimerActive] = useState(true);
  let [color, setColor] = useState("#4292cf");

  let [query, setQuery] = useQueryParams({
    redirect: StringParam
  })

  const { redirect } = query

  useEffect(() => {
    let interval;

    if (timerActive && countdown > 0) {
      interval = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timerActive, countdown]);

  useEffect(() => {
    localStorage.setItem('countdown', countdown.toString());
  }, [countdown]);

  const params = new URLSearchParams(window.location.search);
  const authType = params.get('type');
  const authValue = params.get('value');

  const handleInputChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace') {
      handleInputChange(index, '');
    }
  };

  const handleInputFocus = (index) => {
    setActiveInput(index);
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const paste = e.clipboardData.getData('text');
    let stringArrayOtp = paste.split("");
    setOtp(stringArrayOtp);
    setActiveInput(5)
  };

  const handleKeyUp = (index, e) => {
    if (e.key === 'Backspace' && otp[index] === '') {
      if (index !== 0) {
        setActiveInput(index - 1);
      }
    } else if (e.key >= '0' && e.key <= '9') {
      if (index < 5) {
        setActiveInput(index + 1);
      }
    }
  };

  const renderInputs = () => {
    const inputs = [];
    for (let i = 0; i < 6; i++) {
      inputs.push(
        <input
          key={i}
          className="bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-400 focus:border-blue-400 inline-block w-10 md:w-12 p-2 my-4 mx-2"
          value={otp[i]}
          maxLength={1}
          onChange={(e) => handleInputChange(i, e.target.value)}
          onKeyDown={(e) => handleKeyDown(i, e)}
          onKeyUp={(e) => handleKeyUp(i, e)}
          onFocus={() => handleInputFocus(i)}
          onPaste={(e) => handlePaste(e)}
          ref={(input) => {
            if (input && activeInput === i) {
              input.focus();
              input.select();
            }
          }}
        />
      );
    }
    return inputs;
  };


  const handleSubmit = () => {
    if (otp.join('').length < 6) {
      toast.error(`Please enter 6 digit OTP sent to your ${authType}`)
      return;
    };
    if (authType === "email") {
      setIsLoading(true)
      fetch(`${BACKEND_URL}/otp`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: authValue, otp: otp.join('') })
      }).then((response) => response.json())
        .then((res) => {
          setIsLoading(false)
          if (res.success) {
            toast.success(res.message)
            window.location.href = redirect == undefined ? `${HOST_URL}/dashboard` : `${redirect}`
          }
          else {
            toast.error(res.message);
          }
        })
        .catch(e => {
          setIsLoading(false)
          e.response && toast.error(e.response.data.message)
        })
    } else if (authType === "phone") {
      setIsLoading(true)
      fetch(`${BACKEND_URL}/otp`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phone: authValue, otp: otp.join('') })
      }).then((response) => response.json())
        .then((res) => {
          setIsLoading(false)
          if (res.success) {
            toast.success(res.message)
            window.location.href = redirect == undefined ? `${HOST_URL}/dashboard` : `${redirect}`
          }
          else {
            toast.error(res.message)
          }
        })
        .catch(e => {
          setIsLoading(false)
          toast.error(e.response.data.message)
        })
    }
  }

  const handleResendClick = () => {
    if (countdown <= 0) {
      setIsLoading(true)
      if (authType == "email") {
        fetch(`${BACKEND_URL}/resend`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email: authValue, type: authType })
        }).then((response) => response.json())
          .then((res) => {
            setIsLoading(false)
            if (res.success) {
              setCountdown(300);
              setTimerActive(true);
            }
            else {
              toast.error(res.message);
            }
          })
          .catch(e => {
            setIsLoading(false)
            e.response && toast.error(e.response.data.message)
          })
      }
      if(authType == "phone") {
        fetch(`${BACKEND_URL}/resend`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ phone: "880" + authValue, type: authType })
        }).then((response) => response.json())
          .then((res) => {
            setIsLoading(false)
            if (res.success) {
              setCountdown(300);
              setTimerActive(true);
            }
            else {
              toast.error(res.message);
            }
          })
          .catch(e => {
            setIsLoading(false)
            e.response && toast.error(e.response.data.message)
          })
      }
    }
  };

  const minutes = Math.floor(countdown / 60);
  const seconds = countdown % 60;

  return (
    <div className="flex flex-col p-8">
      <div className="mx-auto">
        <Toaster />
        <img src={require('../images/logo.jpg')} alt="logo" className="h-10 w-10 inline-block mx-auto" />
        <span className="font-bold">Apars Classroom</span>
      </div>
      <div className="m-auto">
        <div className="mx-auto grid place-items-center">
          <img style={{ height: '140px' }} alt="phone-logo" className="mx-auto" src={phone} />
          <p className="text-center">Check your {authType == 'phone' ? "Phone" : "email"} for an OTP</p>
          <br />
          <div>
            {renderInputs()}
          </div>
          {countdown > 0 ? (
            <p className="text-sm">
              Resend OTP in: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
            </p>
          ) : (
            <button onClick={handleResendClick} className="text-red-400 text-sm underline">Resend OTP</button>
          )}
          <br />
          <button onClick={handleSubmit} className="bg-blue-400 text-center w-40 px-4 py-2 rounded-lg hover:ring hover:ring-blue-500 hover:shadow-2xl">Confirm</button>
        </div>

      </div>
      <Modal
        isOpen={isLoading}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="mx-auto my-auto">
          <HashLoader
            color={color}
            loading={isLoading}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </Modal>
    </div>
  )
}
export default Verification