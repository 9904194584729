/* eslint-disable */
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { StringParam, useQueryParams } from 'use-query-params'

const HOST_URL = process.env.REACT_APP_HOST_URL
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const Dashboard = () => {
  const [user, setUser] = useState()
  const [name, setName] = useState('')
  const [role, setRole] = useState('')
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  let [query, setQuery] = useQueryParams({
    redirect: StringParam
  })
  const { redirect } = query

  useEffect(() => {
    // Check if user is authenticated, in this case, by checking if token exists
    let email = localStorage.getItem('email')
    let phone = localStorage.getItem('phone')
  
    async function fetcher() {
      if (!email) {
        return await fetch(`${BACKEND_URL}/memberCookies`, {
          method: 'post',
          body: JSON.stringify({ phone: phone }),
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => res.json()).then(res => {
          setUser(res.User)
          setIsAuthenticated(true)
        })
      } else if (!phone) {
        return await fetch(`${BACKEND_URL}/memberCookies`, {
          method: 'post',
          body: JSON.stringify({ email: email }),
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => res.json()).then(res => {
          setUser(res.User)
          setIsAuthenticated(true)
        })
      }
    }
  
    fetcher().then(token => {
      if (!user || token.success === false || user.verified === false) {
        setIsAuthenticated(false)
      }
    });
  }, [user]);
  
 
  return (
    <div style={{ height: '100%', width: '100%' }} className='mx-auto flex flex-col items-center align-middle'>
      <div className='m-auto'>
          <div>
            <p className='text-2xl font-bold md:text-5xl text-center'>ACS Membership Dashboard</p>
            <br />
          </div>
        <h1 className='text-center'>Welcome, <strong>{name}</strong></h1>
        <p className='text-center'>Stay tuned for upcoming features!</p>
      </div>
    </div>
  )
}
export default Dashboard;